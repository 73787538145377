.main_faqsnew {
    padding: 48px 100px 40px 100px;
    background: #FFF;
    .outer_third {
        .inner_inte {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 63px;
            h5 {
                border: 0.1px solid #D9D9D9;
              
                width: 100%;
            }

            h2 {
                color: #2C83F6;
                white-space: nowrap;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 130%;
                /* 15.6px */
            }

            .imgline {
                width: 100%;
            }
        }
h1{
    color: #141414;
    font-family: Pregular;
font-size: 60px;
align-items: center;
margin-bottom: 55px;
text-align: center;
font-style: normal;
line-height: 100%; /* 60px */
}
        .inner_faqs {
            display: flex;
            align-items: flex-start;
            gap: 20px;
            width: 100%;
.faqs_cards{
    width: 100%;
    padding: 40px;
    border-radius: 20px;
    height: 341px;

    background: #F7F7FD;
}
            h2 {
                color: rgba(1, 1, 2, 0.30);
                font-family: Pregular;
                font-size: 25px;
                font-style: normal;
                line-height: 100%;
                /* 25px */
            }

            h3 {
                color: #010102;
                font-family: Pregular;
                padding-top: 80px;
                padding-bottom: 22px;
                font-size: 24px;
                font-style: normal;
                line-height: 100%;
                /* 24px */
            }

            p {
                color: #141414;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                /* 22.4px */
                width: 390.85px;
            }
        }
    }
}
@media (max-width: 400px) {
    .main_faqsnew .outer_third .inner_faqs .faqs_cards {
      height: unset !important;
    }
}
@media (max-width: 990px) {
    .main_faqsnew {
        .imgline{
            display: none;
        }
        h5{
            display: block !important;
        }
        padding: 20px 0px;
        .inner_inte {
            margin-bottom: 30px !important;
        }

        .outer_third {
            .inner_faqs{
                .faqs_cards{
                    padding: 40px 20px;

                }
                flex-direction: column;
                p{
                    width: 100%;
                }
                h3{
                    padding-top: 30px;
                }
            }
            h1{
                font-size: 28px;
margin-bottom: 30px;
            }

            .third_flex {
                flex-direction: column;
                gap: 30px;
                h1{
                    font-size: 28px;

                }
                h3{
                    font-size: 28px;

                }
                p{
                    width: 100%;
                }
            }

        }
    }
}