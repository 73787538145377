.blogs_more_mains {
    .bottomm_more {
        padding: 60px 0px 80px 0px;
        background: #FFF;
        h3 {
            color: #141414;
            font-family: pregular;
            font-size: 40px;
            font-style: normal;
            line-height: 120%;
            /* 48px */
        }
      
        .img_topdiv{
            width: 100%;
            object-fit: cover;
            height: 480px;
            padding-top: 25px;
            width: 100%;
        overflow: hidden;
            border-radius: 20px;

        }

        .moreimg {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 20px;
        }

        p {
            color: #777;
            font-size: 18px;
            padding-top: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
            /* 25.2px */
        }

        h4 {
            color: #141414;
            font-family: pregular;
            font-size: 26px;
            font-style: normal;
            padding-top: 25px;
            line-height: 120%;
            /* 31.2px */
        }
    }

    .top_blogs_more {
        position: relative;
        height: 810px;
        overflow: hidden;

        .imgbgmore {
            position: absolute;
            z-index: -1;
            top: 0px;
            width: 100%;
            object-fit: cover;
        }

        .textual_div {
            position: absolute;
            bottom: 81px;
            width: 986px;
            left: 100px;

            h2 {
                color: #FFF;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 140%;
                /* 25.2px */
            }

            h1 {
                color: #FFF;
                font-family: pregular;
                font-size: 50px;
                font-style: normal;
                padding-top: 25px;
                padding-bottom: 25px;
                line-height: 100%;
                /* 50px */
            }

            p {
                overflow: hidden;
                color: #FFF;
                text-overflow: ellipsis;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                /* 25.2px */
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 5;
                align-self: stretch;
            }
        }
    }
}
.new__textual{
    padding-top: 0px !important;
}
@media (max-width:1200px){
    .blogs_more_mains .top_blogs_more .imgbgmore {
        position: absolute;
        z-index: -1;
        top: 0px;
        height: 100%;
        left: 0px;
        width: 100%;
        object-fit: cover;
    }
    .blogs_more_mains .top_blogs_more .textual_div h1 {
        color: #FFF;
        font-family: pregular;
        font-size: 32px;
        font-style: normal;
        padding-top: 25px;
        padding-bottom: 25px;
        line-height: 100%;
    }.blogs_more_mains .top_blogs_more .textual_div p {
        overflow: hidden;
        color: #FFF;
        text-overflow: ellipsis;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
        align-self: stretch;
    }
    .blogs_more_mains .top_blogs_more .textual_div {
        position: absolute;
        bottom: 61px;
        width: 100%;
        left: 20px;
        padding-right: 36px;    
    }
    .blogs_more_mains .bottomm_more h3 {
        color: #141414;
        font-family: pregular;
        font-size: 26px;
        font-style: normal;
        line-height: 120%;
    }
    .blogs_more_mains .bottomm_more p {
        color: #777;
        font-size: 16px;
        padding-top: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
    }
    .blogs_more_mains .bottomm_more h4 {
        color: #141414;
        font-family: pregular;
        font-size: 22px;
        font-style: normal;
        padding-top: 25px;
        line-height: 120%;
    }
    .section_new{
        padding: 20px;
    }
    .imgg_phome{
        display: block !important;
    }
    .imgg_bggg{
        display: none !important;
    }
    .img_topdiv{
        height: 246px !important;
        width: 100%;
        .imgg_phome{
            border-radius: 15px;
            width: 100%;
            height: 100%;
        }
    }
}