.main_feat1 {
    padding: 80px 0px;

    .outer_feat {
        .inner_inte {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 63px;
            h5 {
                border: 0.1px solid #D9D9D9;
              
                width: 100%;
            }
            h2 {
                color: #2C83F6;
                white-space: nowrap;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 130%;
                /* 15.6px */
            }

            .imgline {
                width: 100%;
            }
        }
.inner_works_data{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 60px;
    .top_data{
        h1{color: #141414;
            font-family: Pregular;
            font-size: 60px;
            font-style: normal;
        
            line-height: 100%; /* 60px */}
            p{
                color: #141414;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 23.4px */
        padding-top: 45px;
            }
    }
    .bottom_data{
        display: flex;
        align-items: center;
        gap: 15px;
        flex-direction: column;
        .inner__cards{
            padding: 30px;
            border-radius: 20px;
            background: #FFF;
            width: 100%;
            h2{
                color: #010102;
                font-family: Pregular;
font-size: 24px;
font-style: normal;

line-height: 100%; /* 24px */
            }
            p{
                color: #141414;
font-family: Montserrat;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 21px */
padding-top: 22px;
            }
        }
    }
   
}
    }
}
@media (max-width: 990px) {
    .main_feat1 .outer_feat .inner_works_data {
        flex-direction: column;
        gap: 30px;
    }
    .main_feat1 .outer_feat .inner_works_data .bottom_data .inner__cards h2 {
        font-size: 22px;
    }
    .main_feat1 .outer_feat .inner_works_data .top_data h1 {
        font-size: 26px;
    }
    .main_feat1 .outer_feat .inner_works_data .top_data p {
        font-size: 16px;
        padding-top: 20px;
    }
    .main_feat1{
        .imgline{
            display: none;
        }
        h5{
            display: block !important;
        }
       padding: 20px 0px;
       .inner_inte{
        margin-bottom: 30px !important;
       }
       .outer_feat {
           h1{
            font-size: 26px;
            width: 100%;
           }
           p{
               font-size: 16px;
   width: 100%;
           }
           .inner_feat{
            flex-direction: column;
            gap: 50px;
            margin-top: 30px;
            h2{
                margin-top: 30px;
                font-size: 22px;
            }
            p{
                width: 100%;
            }
           }
       }
    }
   }
   @media (max-width: 1200px) {
    .main_feat{
       
           .inner_feat{
            flex-direction: column;
            gap: 50px;
            margin-top: 30px;
       
           }
       }
    }
   