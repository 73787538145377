.main_third {

    position: relative;


    .fillter {
        position: absolute;
        width: 100%;
        bottom: 0px;
        z-index: -1;
    }

    .outer_third {
        padding: 80px 0px;
        padding-top: 48px;

        .inner_inte {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 63px;
     h5 {
                border: 0.1px solid #D9D9D9;
              
                width: 100%;
            }
            h2 {
                color: #2C83F6;
                white-space: nowrap;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 130%;
                /* 15.6px */
            }

            .imgline {
                width: 100%;
            }
        }

        .third_flex {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h1 {
                color: #141414;
                font-family: Pregular;
                font-size: 60px;
                font-style: normal;
                line-height: 100%;
                /* 60px */
            }

            h3 {
                background: var(--Gradiend, linear-gradient(97deg, #2C83F6 13.16%, #EDA0FE 53.89%, #FD7C52 95.45%));
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-family: Pbold;
                font-size: 60px;
                font-style: normal;
                line-height: 100%;
            }

            p {
                color: #141414;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 130%;
                /* 26px */
                padding-top: 45px;
                width: 614px;
            }
        }
    }

}

@media (max-width: 990px) {
    .main_third {
       .fillter {
            position: absolute;
            width: 100%;
            bottom: 0px;
            z-index: -1;
            opacity: 0.3;
        }
        .imgline{
            display: none;
        }
        h5{
            display: block !important;
        }
        .inner_inte {
            margin-bottom: 30px !important;
        }

        .outer_third {
            padding: 20px 0px;

            .third_flex {
                flex-direction: column-reverse;
                gap: 30px;
                h1{
                    font-size: 28px;

                }
                h3{
                    font-size: 28px;

                }
                p{
                    width: 100%;
                    padding-top: 20px;
                }
            }

        }
    }
}
@media (max-width: 1350px) {
    
    .main_third .outer_third .third_flex {
        flex-direction: column-reverse;
        gap: 30px;
    }
}
.gradient{
    position: absolute;
    bottom: 0px;
}