@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Albert+Sans:ital,wght@0,100..900;1,100..900&family=Cormorant+Infant:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Unbounded:wght@200..900&family=Vesper+Libre:wght@400;500;700;900&display=swap');

@font-face {
  font-family: Plight;
  src: url(../src/font/Parsi-Light.ttf);
}

@font-face {
  font-family: Pregular;
  src: url(../src/font/Parsi-Regular.ttf);
}

@font-face {
  font-family: Pbold;
  src: url(../src/font/Parsi-Bold.ttf);
}




html {
  scroll-behavior: smooth;
}

body {
  background: #F7F7FD;
  font-family: "Montserrat", serif;
  min-height: 100vh;

  &::-webkit-scrollbar {
    width: 5px;
  }

  .custom-container{
    max-width: 1330px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
  }


  &::-webkit-scrollbar-thumb {
    background: #2C83F6;
    border-radius: 10px;
  }


  &::-webkit-scrollbar-thumb:hover {
    background: rgb(79, 151, 244);
  }

}


.row {
  margin: 0;
  padding: 0;
}

.p-0 {
  padding: 0px;
}

.p0 {
  padding: 0px;
}

// All transitions
div,
ul,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
button {
  -webkit-transition: background-color 0.7s ease-out;
  -moz-transition: background-color 1s ease-out;
  -o-transition: background-color 1s ease-out;
  transition: background-color 1s ease-out;
}

textarea {
  resize: none;
  height: 120px;
  width: 100%;
}

a {
  text-decoration: none !important;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

div,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0;
}



.cursor-pointer {
  cursor: pointer;
}


iframe {
  position: unset !important;
  top: 0px !important;
  left: 0px !important;
  width: 0px !important;
  height: 0px !important;
}

button {
  cursor: pointer;
}


.wrapper-structure {
  // display: grid;
  // grid-template-columns: 4.38vw 1fr 4.38vw;
  // grid-template-rows: 4.38vw 1fr;
  // grid-template-areas:
  //   "left-side top-side right-side"
  //   "left-side mid-side right-side";
  // gap: 1px;
}

.wrapper-withouttopside {
  .wrapper-structure {
    // grid-template-columns: 4.38vw 1fr 4.38vw;
    // grid-template-rows: 1fr;
    // grid-template-areas:
    //   "left-side mid-side right-side";
  }
}

.left-side-grid {
  grid-area: left-side;
  border-radius: 0px 0px 10px 0px;
  background: #F2F2F2;

}

.top-side-grid {
  grid-area: top-side;
  // border-radius: 0px 0px 10px 10px;
  background: #F2F2F2;

}

.mid-side-grid {
  grid-area: mid-side;

}

.right-side-grid {
  grid-area: right-side;
  border-radius: 0px 0px 0px 10px;
  background: #E7EDF4;
}



.btn-container {
  display: inline-block;
  overflow: hidden;
}

.btn-blue {
	border: 0;
	position: relative;
	color: #fff;
	background: #2C83F6;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
  border-radius: 0.25vw;
  color: #EFF2F6;
  font-size: 0.875vw;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; 
  text-transform: uppercase;



	.text,
	.icon-container {
		position: relative;
		z-index: 2;
	}

	.icon-container {
		--icon-size: 1.125vw;
		position: relative;
		width: var(--icon-size);
		height: var(--icon-size);
		margin-left: 0.94vw;
		transition: transform 500ms ease;

		.icon {
			position: absolute;
			left: 0;
			top: 0px;
			width: var(--icon-size);
			height: var(--icon-size);
			transition: transform 500ms ease, opacity 250ms ease;

			&--left {
				transform: translateX(-200%);
				opacity: 0;
			}

			
		}
	}

	&::after {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: #ffffff23;
    mix-blend-mode: lighten;
		border-radius: 5px;
		z-index: 1;
		transition: transform 500ms ease;
    transform: translateX(0%);
	}

	&:hover {
		&::after {
			transform: translateX(72%);
    
      
		}

		.icon-container {
			transform: translateX(125%);
			.icon {
				&--left {
					transform: translateX(-17px);
					opacity: 1;
				}
				&--right {
					transform: translateX(200%);
					opacity: 0;
				}
			}
		}
	}
}

.btn-white {
	border: 0;
	position: relative;
	// background: #FFF;
  background: #eff6ff;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
  border-radius: 0.25vw;
  color: #2C83F6;
  font-size: 0.875vw;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; 
  text-transform: uppercase;



	.text,
	.icon-container {
		position: relative;
		z-index: 2;
	}

	.icon-container {
		--icon-size: 1.125vw;
		position: relative;
		width: var(--icon-size);
		height: var(--icon-size);
		margin-left: 0.94vw;
		transition: transform 500ms ease;

		.icon {
			position: absolute;
			left: 0;
			top: 0px;
			width: var(--icon-size);
			height: var(--icon-size);
			transition: transform 500ms ease, opacity 250ms ease;

			&--left {
				transform: translateX(-200%);
				opacity: 0;
			}

			
		}
	}

	&::after {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
    background-color: #fff;
    mix-blend-mode: lighten;
		border-radius: 5px;
		z-index: 1;
		transition: transform 500ms ease;
    transform: translateX(0%);
	}

	&:hover {
		&::after {
			transform: translateX(72%);
    
      
		}

		.icon-container {
			transform: translateX(125%);
			.icon {
				&--left {
					transform: translateX(-17px);
					opacity: 1;
				}
				&--right {
					transform: translateX(200%);
					opacity: 0;
				}
			}
		}
	}
}



@media (max-width:600px) {
  .wrapper-banner .left-side-grid {
    display: none !important;
  }
  .wrapper-banner .right-side-grid{
    display: none !important;
  }
  .wrapper-structure{
    // display: grid;
    // grid-template-columns: 1fr !important;
    // grid-template-rows: 1fr !important;
    // grid-template-areas:
    //   "top-side"
    //   "mid-side" !important;
    // gap: 0px;
  }
  
  .btn-blue {
    border: 0;
    position: relative;
    color: #fff;
    background: #2C83F6;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    color: #EFF2F6;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; 
    text-transform: uppercase;
  
  
  
    .text,
    .icon-container {
      position: relative;
      z-index: 2;
    }
  
    .icon-container {
      --icon-size: 18px;
      position: relative;
      width: var(--icon-size);
      height: var(--icon-size);
      margin-left: 15px;
      transition: transform 500ms ease;
  
      .icon {
        position: absolute;
        left: 0;
        top: 0px;
        width: var(--icon-size);
        height: var(--icon-size);
        transition: transform 500ms ease, opacity 250ms ease;
  
        &--left {
          transform: translateX(-200%);
          opacity: 0;
        }
  
        
      }
    }
  
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #ffffff23;
      mix-blend-mode: lighten;
      border-radius: 5px;
      z-index: 1;
      transition: transform 500ms ease;
      transform: translateX(0%);
    }
  
    &:hover {
      &::after {
        transform: translateX(72%);
      
        
      }
  
      .icon-container {
        transform: translateX(125%);
        .icon {
          &--left {
            transform: translateX(-17px);
            opacity: 1;
          }
          &--right {
            transform: translateX(200%);
            opacity: 0;
          }
        }
      }
    }
  }
  
  .btn-white {
    border: 0;
    position: relative;
    // background: #FFF;
    background: #eff6ff;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    color: #2C83F6;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; 
    text-transform: uppercase;
  
  
  
    .text,
    .icon-container {
      position: relative;
      z-index: 2;
    }
  
    .icon-container {
      --icon-size: 18px;
      position: relative;
      width: var(--icon-size);
      height: var(--icon-size);
      margin-left: 15px;
      transition: transform 500ms ease;
  
      .icon {
        position: absolute;
        left: 0;
        top: 0px;
        width: var(--icon-size);
        height: var(--icon-size);
        transition: transform 500ms ease, opacity 250ms ease;
  
        &--left {
          transform: translateX(-200%);
          opacity: 0;
        }
  
        
      }
    }
  
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #fff;
      mix-blend-mode: lighten;
      border-radius: 5px;
      z-index: 1;
      transition: transform 500ms ease;
      transform: translateX(0%);
    }
  
    &:hover {
      &::after {
        transform: translateX(72%);
      
        
      }
  
      .icon-container {
        transform: translateX(125%);
        .icon {
          &--left {
            transform: translateX(-17px);
            opacity: 1;
          }
          &--right {
            transform: translateX(200%);
            opacity: 0;
          }
        }
      }
    }
  }
  
}




// animated button styles ................................



