.bannermain {
    // background-image: url(../../../assets/bannerbg.png);
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-position: center;
    position: relative;
    // width: 100%;
    // margin: 0;

    .banner_outer {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 278px;
        padding-bottom: 278px;
        h1 {
            color: #141414;
            text-align: center;
            font-family: Pregular;
            font-size: 60px;
            font-style: normal;
            line-height: 100%;
            /* 60px */
            width: 782px;
        }

        p {
            color: #141414;
            text-align: center;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
            /* 26px */
            width: 782px;
            padding-top: 20px;
        }
        .buttons_main{
            display: flex;
align-items: center;
gap: 10px;
margin-top: 50px;
.getstarted{
    color: #FFF;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 120%; /* 14.4px */ 
padding: 12px 20px;
border-radius: 129.032px;
border: unset;
background: #2C83F6;
}
.learn{
    color: #141414;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 120%; /* 14.4px */
padding: 12px 20px;
border-radius: 129.032px;
border: unset;
background: rgba(255, 255, 255, 0.40);
backdrop-filter: blur(5px);
}
        }
    }
}
.main-banner-video {
    position: absolute;
    height: 100%;
    object-fit: cover;
    object-position: center;
    top: 0px;
    left: 0;
    width: 100%;
    z-index: -2;
}

@media (max-width: 990px) {
 .bannermain{
    padding: 20px;
    .banner_outer{
        h1{
            font-size: 29px;
width: 100%;
        }
        p{
            font-size: 16px;
width: 100%;
        }
    }
 }
}