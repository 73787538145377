.termscontent {
  padding: 70px 15px;
  border-radius: 10px;
  padding-bottom: 40px;
  background: #F7F7FD;
  
  .termsinnercontent {
    max-width: 978px;
    width: 100%;
    margin: 0 auto;

    .datepara {
      color: #020f20;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
    }
  }
}

.mainouterdiv_outer {
  padding-top: 40px;
  .mainouterdiv_iner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // gap: 34px;
    align-self: stretch;
    .maintophngd {
      color: #020f20;
      font-family: Pregular;
      font-size: 30px;
      font-style: normal;
      line-height: 100%;
      margin-bottom: 34px;
    }
    .seclinghthndg {
      color: #020f20;
      font-family: Pregular;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
        // margin-top: 34px;
        margin-bottom: 24px;
    }
    p.commmmonpara {
      color: #020f20;
      font-family: "Noto Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      padding-bottom: 34px;
    }
    p.ullipara {
      color: #020f20;
      font-family: "Noto Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      padding-bottom: 10px;
    }
    ul {
      display: flex;
      flex-direction: column;
      gap: 10px;
      li {
        list-style: disc;
        margin-left: 23px;
        color: #020f20;
        font-family: "Noto Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
      }
    }
  }
  .mainitemsdiv {
    // padding-top: 34px;
    padding-bottom: 34px;
}
}

@media (max-width: 991px) {
  .termscontent {
    margin-top: -20px;
    padding: 40px 15px;
  }
  .wrapper-banner {
    padding: 20px;
  }
}
