.main-footer {
    background: #FFF;
    padding: 3.125vw 6.25vw 4.1875vw 6.25vw; // 50px 100px 67px 100px
    .hover-img {
        transition: filter 0.3s ease;
      }
      
      .hover-img:hover {
        filter: invert(40%) sepia(100%) saturate(7400%) hue-rotate(200deg) brightness(90%);
      }
      .send{
        width: 28px !important;
    height: 28px !important;
      }
    .teleimgs {
        display: flex;
        align-items: center;
        gap: 5px;
        margin-top: 30px;
    }

    .parent {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .left-side {
            .inside-content {
                .logo-img {
                    width: 9.69vw;
                }

                .social-icons {
                    margin: 1.875vw 0; // 30px -> (30 / 1600) * 100
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 0.3125vw; // 5px -> (5 / 1600) * 100

                    a {
                        border-radius: 100px;
                        background: rgba(2, 15, 32, 0.04);
                   // 28px -> (28 / 1600) * 100
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        transition: 0.3s linear;

                        svg {
                       

                            path {
                                transition: 0.3s linear;
                            }

                        }

                        &:hover {
                            svg {
                                path {
                                    fill: #2C83F6;
                                }
                            }
                        }

                    }
                }

                p {
                    color: rgba(2, 15, 32, 0.50);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 100%;
                    /* 14px */
                }

                .twice-links {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    margin-top: 15px;

                    span {
                        background-color: #828C97;
                        width: 1px;
                        height: 12px;
                        display: flex;
                    }

                    a {
                        color: rgba(2, 15, 32, 0.50);
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 100%;
                        /* 12px */
                    }
                }
            }
        }

        .right-side {
            display: flex;

            gap: 60px;

            .parent-inside {
                display: flex;
                justify-content: center;
                align-items: flex-start;
                gap: 3.625vw; // 58px -> (58 / 1600) * 100

                .single-list {
                    h6 {
                        color: #020F20;
                        font-size: 0.875vw; // 14px -> (14 / 1600) * 100
                        font-style: normal;
                        font-weight: 700;
                        line-height: 100%;
                        text-transform: uppercase;
                        font-family: Pbold;
                    }

                    a {
                        display: block;
                        color: #141414;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 100%;
                        /* 18px */
                        transition: 0.3s linear;
                        margin-top: 0.9375vw; // 15px -> (15 / 1600) * 100

                        &:hover {
                            color: #2C83F6;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width:600px) {
    .main-footer {
        padding: 40px 25px;
    }

    .main-footer .parent {
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;
    }

    .main-footer .parent .left-side .inside-content .logo-img {
        width: auto;
        display: block;
        margin: 0 auto;
    }

    .main-footer .parent .left-side .inside-content .social-icons {
        margin: 0 auto;
        margin-top: 22px;
        gap: 5px;
    }

    .main-footer .parent .left-side .inside-content .social-icons a {
        width: 28px;
        height: 28px;
    }

    .main-footer .parent .left-side .inside-content .social-icons a svg {
        width: auto;
        height: auto;
    }

    .main-footer .parent .left-side .inside-content p {
        display: none;
    }

    .main-footer .parent .right-side .parent-inside {
        gap: 20px;
    }

    .main-footer .parent .right-side .parent-inside .single-list h6 {
        font-size: 14px;
    }

    .main-footer .parent .right-side .parent-inside .single-list a {
        font-size: 14px;
        margin-top: 15px;
    }

    .right-reserved-text {
        display: flex !important;
        justify-content: flex-start;
        align-items: start;
        color: rgba(2, 15, 32, 0.50);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        margin-top: 50px;
    }

    .main-footer .parent .left-side .inside-content .twice-links {
        display: none;
    }
}

.twice-links {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 15px;

    span {
        background-color: #828C97;
        width: 1px;
        height: 12px;
        display: flex;
    }

    a {
        color: rgba(2, 15, 32, 0.50);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
    }
}
@media (max-width: 990px){
    .right-side{
        display: block !important;
    }
    .parent{
        gap: 30px !important;
    }
}