.faqs-section {
    height: 100%;
    padding-top: 215px;
    padding-bottom: 190px;
    position: relative;

    .faqs-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
        pointer-events: none;
    }

    .main-content {
        max-width: calc(736 / 1600 * 100vw);
        width: 100%;
        margin: 0 auto;

        h6 {
            color: #2C83F6;
            font-size: calc(12 / 1600 * 100vw);
            font-style: normal;
            font-family: Pregular;
            line-height: 100%;
            text-transform: uppercase;

            text-align: center;
        }

        h4 {
            margin: calc(30 / 1600 * 100vw) 0;
            color: #020F20;
            text-align: center;
            font-size: 58px;
            font-style: normal;
            line-height: 100%;
            text-transform: uppercase;
            font-family: Pregular;
        }

        p {
            color: #141414;
            text-align: center;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            /* 28px */
        }
    }
}

.mediakit_side {
    height: 360px;



    .mediakit_side {
        padding-top: 52px !important;

        h4 {
            color: #141414;
            text-align: center;
            font-size: 60px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            /* 60px */
            text-transform: capitalize;
            font-family: Pregular;
        }
    }
}

.mains_media {
    padding: 50px 0px;

    .imgsymbol {
        width: 100%;
    }
    .img_width{
        width: 100%;
    }
    .fonttss {
        padding-top: 100px;
    }

    .colorsss {
        padding-top: 50px;
    }

    .full_llogosthrd {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;

        .parsi {
            padding: 40px;
            border-radius: 20px;
            background: #FFF;

            h5 {
                color: #111;
                -webkit-text-stroke-width: 1.69px;
                -webkit-text-stroke-color: rgba(17, 17, 17, 0.44);
                font-size: 40px;
                font-style: normal;
                font-weight: 300;
                line-height: 100%;
                /* 40px */
            }

            .inner_parsi {
                padding: 66px 96px;
                border-radius: 20px;
                background: rgba(44, 131, 246, 0.10);
                margin-top: 50px;
                margin-bottom: 50px;

                h6 {
                    color: #111;
                    -webkit-text-stroke-width: 1.69px;
                    -webkit-text-stroke-color: rgba(17, 17, 17, 0.44);
                    font-family: pregular;
                    font-size: 195.08px;
                    font-style: normal;
                    text-align: center;
                    font-weight: 400;
                    line-height: 100%;
                    /* 195.08px */
                }
            }

            .inner_parsi1 {
                padding: 66px 96px;
                border-radius: 20px;
                background: rgba(44, 131, 246, 0.10);
                margin-top: 50px;
                margin-bottom: 50px;

                h6 {
                    color: #111;
                    -webkit-text-stroke-width: 1.69px;
                    -webkit-text-stroke-color: rgba(17, 17, 17, 0.44);
                    font-size: 195.08px;
                    font-style: normal;
                    text-align: center;
                    font-weight: 400;
                    line-height: 100%;
                    /* 195.08px */
                }
            }

            h4 {
                color: #111;
                font-family: pregular;
                font-size: 50px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%;
                /* 50px */
            }

            .new_fonnt {
                color: #111;

                font-size: 50px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%;
                /* 50px */
            }
        }
    }

    .inner_inte {
        display: flex;
        align-items: center;
        gap: 10px;
        width: 100%;

        h5 {
            border: 0.1px solid #D9D9D9;

            width: 100%;
        }

        h2 {
            color: #2C83F6;
            white-space: nowrap;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
            /* 15.6px */
        }

        .imgline {
            width: 100%;
        }
    }

    .our__logo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 55px;
        padding-bottom: 55px;

        h3 {
            color: #141414;
            font-size: 60px;
            font-family: Pregular;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            /* 60px */
        }

        button {
            color: #141414;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            /* 14.4px */
            padding: 17px 34px;
            border-radius: 129.032px;
            background: #FFF;
            border: unset;
        }
    }

    .vbottom_cards {
        height: 363px;
        border-radius: 20px;
        background: #111;
        display: flex;
        flex-direction: column;
        padding: 0px 35px 35px 35px;
        align-items: flex-start;
        gap: 20px;
        justify-content: end;

        h4 {
            color: #FFF;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            /* 24px */
            text-transform: capitalize;
        }

        h6 {
            color: #FFF;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            text-transform: uppercase;
        }
    }

    .vbottom_cards1 {
        height: 363px;
        border-radius: 20px;
        border: 1px solid #D9D9D9;
        background: #FFF;
        display: flex;
        flex-direction: column;
        padding: 0px 35px 35px 35px;
        align-items: flex-start;
        gap: 20px;
        justify-content: end;

        h4 {
            color: #000;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            /* 24px */
            text-transform: capitalize;
        }

        h6 {
            color: #000;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            text-transform: uppercase;
        }
    }

    .vbottom_cards2 {
        height: 363px;
        border-radius: 20px;
        background: #F2F2F2;
        display: flex;
        flex-direction: column;
        padding: 0px 35px 35px 35px;
        align-items: flex-start;
        gap: 20px;
        justify-content: end;

        h4 {
            color: #1E1E1E;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            /* 24px */
            text-transform: capitalize;
        }

        h6 {
            color: #1E1E1E;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            text-transform: uppercase;
        }
    }

    .vbottom_cards3 {
        height: 363px;
        border-radius: 20px;
        background: #D6D6DD;
        display: flex;
        flex-direction: column;
        padding: 0px 35px 35px 35px;
        align-items: flex-start;
        gap: 20px;
        justify-content: end;

        h4 {
            color: #1E1E1E;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            /* 24px */
            text-transform: capitalize;
        }

        h6 {
            color: #1E1E1E;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            text-transform: uppercase;
        }
    }

    .full_llogosone {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 22px;
    }

    .full_llogos {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-bottom: 55px;
        gap: 20px;


        .color_divone {
            border-radius: 20px;
            border: 0px solid #FFF;
            background: #2C83F6;
            height: 414px;
            display: flex;
            align-items: end;
            padding: 0px 35px 35px 35px;
            justify-content: space-between;

            h4 {
                color: #FFF;
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
                /* 24px */
                text-transform: capitalize;
            }

            h6 {
                color: #FFF;
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
                text-transform: uppercase;
            }
        }

        .color_divtwo {
            border-radius: 20px;
            border: 0px solid #111;
            background: linear-gradient(97deg, #2C83F6 13.16%, #EDA0FE 53.89%, #FD7C52 95.45%);
            height: 414px;
            display: flex;
            align-items: end;
            padding: 0px 35px 35px 35px;
            justify-content: space-between;

            h4 {
                color: #FFF;
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
                /* 24px */
                text-transform: capitalize;
            }

            h6 {
                color: #FFF;
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
                text-transform: uppercase;
            }
        }

        h5 {
            color: #141414;
            font-size: 14px;
            padding-bottom: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }

    .flex_div_bnew {
        display: flex;
        align-items: center;
        gap: 20px;
    }
}

.wrapper-banner {
    position: relative;
}

.faqs-detail {
    // margin-top: calc(-120 / 1600 * 100vw) !important;
    border-radius: calc(10 / 1600 * 100vw);
    padding: calc(70 / 1600 * 100vw) calc(270 / 1600 * 100vw);
    padding-bottom: calc(120 / 1600 * 100vw);

    .faqs-parent {
        margin-bottom: calc(50 / 1600 * 100vw);

        .faqs-heading {
            h6 {
                color: #020F20;
                font-size: calc(20 / 1600 * 100vw);
                font-style: normal;
                font-family: Pregular;
                line-height: 100%;
                margin-bottom: calc(22 / 1600 * 100vw);

            }
        }

        .accordion {
            .accordion-item {
                border-radius: calc(12 / 1600 * 100vw);
                border: 1px solid #EAF3FE;
                background: #FFF;
                margin-bottom: calc(5 / 1600 * 100vw);

                .accordion-button {
                    border-radius: calc(12 / 1600 * 100vw);
                    border: 1px solid #EAF3FE;
                    background: #FFF;
                    box-shadow: none;
                    padding: calc(15 / 1600 * 100vw);
                    color: #020F20;
                    font-size: calc(14 / 1600 * 100vw);
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;

                    &::after {
                        background: url("../../../src/assets/down-arrow.svg") !important;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                    }
                }

                .accordion-body {
                    border-radius: calc(12 / 1600 * 100vw);
                    border: 1px solid #D9D9D9;

                    background: #F1F1FA;
                    color: #424C58;
                    font-size: calc(14 / 1600 * 100vw);
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%;
                    padding: calc(18 / 1600 * 100vw);
                    border-radius: 0 0 calc(12 / 1600 * 100vw) calc(12 / 1600 * 100vw);
                }
            }

            .accordion-button:not(.collapsed) {

                border: 1px solid #D9D9D9;
                border-bottom: 1px solid transparent;
                background: #F1F1FA;
                border-radius: calc(12 / 1600 * 100vw) calc(12 / 1600 * 100vw) 0 0;
            }
        }
    }
}




@media (max-width:900px) {
    .mains_media .our__logo {
        display: flex
    ;
        align-items: center;
        justify-content: space-between;
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .mains_media .colorsss {
        padding-top: 0px;
    }
    .mains_media .full_llogos .color_divone h4 {
        color: #FFF;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        text-transform: capitalize;
    }
    .mains_media .full_llogos .color_divone {
        border-radius: 20px;
        border: 0px solid #FFF;
        background: #2C83F6;
        height: 250px;
        display: flex
    ;
        align-items: flex-start;
        padding: 0px 20px 20px 20px;
        justify-content: end;
        flex-direction: column;
        gap: 10px;
    }
    .mains_media .full_llogos .color_divtwo {
        border-radius: 20px;
        border: 0px solid #111;
        background: linear-gradient(97deg, #2C83F6 13.16%, #EDA0FE 53.89%, #FD7C52 95.45%);
        height: 250px;
        display: flex
    ;
        align-items: flex-start;
        padding: 0px 20px 20px 20px;
        justify-content: end;
        flex-direction: column;
        gap: 10px;
    }
    .mains_media .vbottom_cards h4 {
        color: #FFF;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        text-transform: capitalize;
    }
    .mains_media .vbottom_cards h6 {
        color: #FFF;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        text-transform: uppercase;
    }
    .mains_media .vbottom_cards1 h4 {
        color: #000;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        text-transform: capitalize;
    }
    .mains_media .vbottom_cards1 h6 {
        color: #000;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        text-transform: uppercase;
    }
    .mains_media .vbottom_cards2 h4 {
        color: #1E1E1E;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        text-transform: capitalize;
    }
    .mains_media .vbottom_cards2 h6 {
        color: #1E1E1E;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        text-transform: uppercase;
    }
    .mains_media .vbottom_cards3 h4 {
        color: #1E1E1E;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        text-transform: capitalize;
    }
    .mains_media .vbottom_cards3 h6 {
        color: #1E1E1E;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        text-transform: uppercase;
    }

    .mains_media .vbottom_cards {
        height: 207px;
        border-radius: 20px;
        background: #111;
        display: flex
    ;
        flex-direction: column;
        padding: 20px;
        align-items: flex-start;
        gap: 20px;
        justify-content: end;
    }
    .mains_media .vbottom_cards1 {
        height: 207px;
        border-radius: 20px;
        border: 1px solid #D9D9D9;
        background: #FFF;
        display: flex
    ;
        flex-direction: column;
        padding: 20px;
        align-items: flex-start;
        gap: 20px;
        justify-content: end;
    }
    .mains_media .vbottom_cards2 {
        height: 207px;
        border-radius: 20px;
        background: #F2F2F2;
        display: flex
    ;
        flex-direction: column;
        padding: 20px;
        align-items: flex-start;
        gap: 20px;
        justify-content: end;
    }
    .mains_media .vbottom_cards3 {
        height: 207px;
        border-radius: 20px;
        background: #D6D6DD;
        display: flex
    ;
        flex-direction: column;
        padding: 20px;
        align-items: flex-start;
        gap: 20px;
        justify-content: end;
    }
    .mains_media .full_llogos .color_divone h6 {
        color: #FFF;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        text-transform: uppercase;
    }
    .mains_media .full_llogos .color_divtwo h4 {
        color: #FFF;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        text-transform: capitalize;
    }
    .mains_media .full_llogos .color_divtwo h6 {
        color: #FFF;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        text-transform: uppercase;
    }
    .mains_media .our__logo h3 {
        color: #141414;
        font-size: 26px;
        font-family: Pregular;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
    }

    .mains_media .full_llogos {
        display: grid;
        grid-template-columns: 1fr;
        margin-bottom: 20px;
        gap: 20px;
    }
    .mains_media .fonttss {
        padding-top: 55px;
    }

    .mains_media .flex_div_bnew {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 20px;
        flex-direction: column;
    }

    .imgsymbol {
        width: 100%;
    }

    .mains_media .full_llogosone {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 22px;
    }

    .mains_media .full_llogosthrd {
        display: grid;
        grid-template-columns: 1fr;
        gap: 30px;
    }

    .mains_media .full_llogosthrd .parsi .inner_parsi {
        padding: 66px 28px;
        border-radius: 20px;
        background: rgba(44, 131, 246, 0.1);
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .mains_media .full_llogosthrd .parsi h4 {
        color: #111;
        font-family: pregular;
        font-size: 34px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
    }

    .mains_media .full_llogosthrd .parsi .new_fonnt {
        color: #111;
        font-size: 34px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
    }

    .mains_media .full_llogosthrd .parsi .inner_parsi1 h6 {
        color: #111;
        -webkit-text-stroke-width: 1.5px;
        -webkit-text-stroke-color: rgba(17, 17, 17, 0.44);
        font-size: 80px;
        font-style: normal;
        text-align: center;
        font-weight: 400;
        line-height: 100%;
    }

    .mains_media .full_llogosthrd .parsi h5 {
        color: #111;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: rgba(17, 17, 17, 0.44);
        font-size: 30px;
        font-style: normal;
        font-weight: 300;
        line-height: 100%;
    }

    .mains_media .full_llogosthrd .parsi .inner_parsi h6 {
        color: #111;
        -webkit-text-stroke-width: 1.5px;
        -webkit-text-stroke-color: rgba(17, 17, 17, 0.44);
        font-family: pregular;
        font-size: 80px;
        font-style: normal;
        text-align: center;
        font-weight: 400;
        line-height: 100%;
    }

    .symbolll {
        width: 100%;
    }

    .faqs-section {
        padding-top: 200px;
        padding-bottom: 200px;
    }

    .faqs-section .main-content h4 {
        font-size: 40px;
        margin: 30px 0;
    }

    .faqs-section .main-content {
        max-width: 100%;
    }

    .faqs-detail {
        padding: 40px 25px;
        margin-top: 0 !important;
    }

    .faqs-section {
        padding-left: 25px;
        padding-right: 25px;
    }

    .faqs-section .main-content h6 {
        font-size: 12px;
    }

    .faqs-section .main-content p {
        font-size: 14px;
    }

    .faqs-detail .faqs-parent .faqs-heading h6 {
        font-size: 20px;
    }

    .faqs-detail .faqs-parent .accordion .accordion-item .accordion-button {
        padding: 15px;
        font-size: 14px;
    }

    .faqs-detail .faqs-parent .accordion .accordion-button:not(.collapsed) {
        border-bottom: 1px solid #E4EEFB;
        border: 1px solid #E4EEFB;
        background: #F8FAFC;
        border-radius: 12px 12px 0 0;
    }

    .faqs-detail .faqs-parent .accordion .accordion-item .accordion-body {
        border-radius: 12px;
        border: 1px solid #E4EEFB;
        background: #F8FAFC;
        color: #424C58;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        padding: 18px;
        border-radius: 0 0 12px 12px;
    }

    .faqs-detail .faqs-parent {
        margin-bottom: 50px;
    }

    .faqs-detail .faqs-parent .faqs-heading h6 {
        margin-bottom: 22px;
    }

    .faqs-detail .faqs-parent .accordion .accordion-item {
        border-radius: 12px;
    }

    .faqs-detail .faqs-parent .accordion .accordion-item .accordion-button {
        border-radius: 12px;
    }

    .faqs-detail .faqs-parent .accordion .accordion-item {
        margin-bottom: 5px;
    }

    .faqs-detail .faqs-parent .accordion .accordion-button:not(.collapsed) {
        border-radius: 12px 12px 0 0;
    }
}
@media (max-width: 400px) {
    .mains_media .full_llogosthrd .parsi h5 {
        color: #111;
        -webkit-text-stroke-width: 0.5px;
        -webkit-text-stroke-color: rgba(17, 17, 17, 0.44);
        font-size: 26px;
        font-style: normal;
        font-weight: 300;
        line-height: 100%;
    }
    .mains_media .full_llogosthrd .parsi .inner_parsi h6 {
        color: #111;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: rgba(17, 17, 17, 0.44);
        font-family: pregular;
        font-size: 60px;
        font-style: normal;
        text-align: center;
        font-weight: 400;
        line-height: 100%;
    }
    .mains_media .full_llogosthrd .parsi h4 {
        color: #111;
        font-family: pregular;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
    }
    .mains_media .full_llogosthrd .parsi .inner_parsi1 h6 {
        color: #111;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: rgba(17, 17, 17, 0.44);
        font-size: 60px;
        font-style: normal;
        text-align: center;
        font-weight: 400;
        line-height: 100%;
    }
    .mains_media .full_llogosthrd .parsi .new_fonnt {
        color: #111;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
    }
}