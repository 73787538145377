.main_faster{
    background-image: url(../../../assets/herobg.png);
    background-color: #2C83F6;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    margin: 0;
    padding: 80px 100px;


    .outer_third{
        display: flex;
        align-items: center;
        flex-direction: column;
        h1{
            color: #FFF;
text-align: center;
font-family: Pregular;
font-size: 65px;
font-style: normal;
width: 598px;
line-height: 100%; /* 65px */
        }
        p{
            color: #FFF;
text-align: center;
width: 886px;
font-size: 22px;
font-style: normal;
font-weight: 400;
line-height: 130%; /* 28.6px */
padding-top: 30px;
        }
        button{
            color: #141414;
            margin-top: 50px;
border: unset;
            font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 120%; /* 14.4px */
padding: 12px 20px;
border-radius: 129.032px;
background: #FFF;
        }
    }
}
@media (max-width: 990px) {
    .main_faster {
        padding:80px 20px;
        .inner_inte {
            margin-bottom: 30px !important;
        }

        .outer_third {
h1{
    font-size: 30px;
width: 100%;
}
p{
    width: 100%;
}

        }
    }
}