.privacycontent {
    padding: 70px 15px;
    border-radius: 10px;
    background: #F7F7FD;
    
    margin-top: 0px;

    .privacyinnercontent {
        max-width: 978px;
        width: 100%;
        margin: 0 auto;

        .datepara {
            color: #020F20;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
        }
    }
}
.span_neww{
    color: var(--secondary, #141414);
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 140%;
}
.new_ticket{
    margin-top: 20px;
    margin-bottom: 20px;
}
@media(max-width:991px){
    .privacycontent{
        margin-top: -20px;
    }
}
@media(max-width:600px){
.privacycontent {
    padding: 40px 15px;
   
}
.mainouterdiv_outer {
    padding-top: 22px;
}
.mainouterdiv_outer .mainouterdiv_iner p.commmmonpara {
    font-size: 14px;
  
    padding-bottom: 20px;
}
.mainouterdiv_outer .mainouterdiv_iner .maintophngd {
  
    font-size: 20px;
   
    margin-bottom: 20px;
}
.mainouterdiv_outer .mainouterdiv_iner p.ullipara {

    font-size: 14px;

}
.mainouterdiv_outer .mainouterdiv_iner ul li {

    font-size: 14px;
 
}
.mainouterdiv_outer .mainouterdiv_iner .seclinghthndg {
    color: #020f20;
    font-family: Pregular;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    margin-bottom: 24px;
}
.mainouterdiv_outer .mainouterdiv_iner .seclinghthndg {

    font-size: 18px;
   
    margin-bottom: 20px;
  
}
.mainouterdiv_outer .mainitemsdiv {
    padding-bottom: 20px;
}
}