.mainnavbar {
    margin: 0px 115px;
    padding-top: 10px;

    .butttons_mains {
        display: flex;
        align-items: center;
        gap: 15px;
    }

    a {

        color: #141414;
        cursor: pointer;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        /* 14px */

    }

    .navbar_outer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 20px;
        border-radius: 50px;
        background: rgba(255, 255, 255, 0.50);
        backdrop-filter: blur(7px);

        .inner_navbar {
            display: flex;
            align-items: center;
            gap: 40px;

            h1 {
                color: #141414;
                cursor: pointer;
                text-align: center;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
                /* 14px */
            }
        }

        button {
            color: #141414;
            font-size: 12px;
            font-style: normal;
            border: unset;
            font-weight: 500;
            line-height: 120%;
            /* 14.4px */
            padding: 10px 15px;
            border-radius: 129.032px;
            background: #F2F2F2;
            width: 124px;
            white-space: nowrap;
            height: 40px;
        }
    }
}

.phone_nav {
    .inner_nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 20px;
        border-radius: 50px;
        background: rgba(255, 255, 255, 0.50);
        backdrop-filter: blur(7px);
    }
}

.offcanvassnav2 {
    .offcanvas-header {
        padding: 23px 20px;

        display: block;
    }

    .top_side {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .offcanvas-header .btn-close {
        display: none;
    }

    height: 100%;
    overflow: hidden;
    width: 100%;
    background: #F4F4F6;
    border: unset;
    border: unset;

    .offcanvas-body {
        padding: 0px;
        .dropdown_navbarrrr{
            .dropdown-toggle::after {
                display: none;
            }
            button#dropdown-basic{
                border-bottom: 1px solid #D9D9D9 !important;
                border-radius: 0px;
                background-color: unset;
                border: unset;
                color: #141414;
                font-size: 14px;
                font-style: normal;
                padding: 20px 25px;

                font-weight: 500;
                line-height: 100%; /* 14px */
                text-transform: uppercase;
                width: 100%;
            }
            .dropdown-menu.show {
                padding: 0px;
                 border-radius: 0px;
                 border: unset;
                 width: 100%;
                 transform: translate3d(0px, 58px, 0px) !important;
            }
            .btn.show{
                background-color: #fff !important;
                transition: 0.1s;
            }
            .inner_iitems{
                padding: 20px 25px 20px 35px;
                border-bottom: 1px solid #D9D9D9;
                background: #FFF;
                display: flex;
                align-items: center;
                gap: 10px;
                &.disabled{
                    pointer-events: none;
                    opacity: 0.5;
                }
            }
            .inner_ddropp{
                display: flex;
                align-items: center;
                gap: 10px;      text-transform: capitalize;

            }
        }
        .innner_offcanvas{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }
        .drop_dowwn {
            display: flex;
            flex-direction: column;

            h1 {
                color: #311E1A;
                padding: 12px;
                border-bottom: 1px solid #E0D7D5;
                background: #F9F9F9;
                font-size: 14px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-style: normal;
                font-weight: 300;
                line-height: 100%;
                /* 14px */
            }

            h1:hover {

                background: #F6F6F5;
            }

            a {
                padding: 20px 25px;
                color: #141414;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
                /* 14px */
                text-transform: capitalize;
                border-bottom: 1px solid #D9D9D9;
            }

            .buy_div {
                position: absolute;
                right: 20px;
                left: 20px;
                bottom: 20px;

                button {
                    color: #FFF;
                    border: unset;
                    width: 100%;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%;
                    /* 14.4px */
                    padding: 20px 15px;
                    border-radius: 129.032px;
                    background: #2C83F6;
                }
            }

            h3 {
                color: #311E1A;
                background: #F9F9F9;
                font-size: 14px;
                display: flex;
                align-items: center;
                gap: 8px;
                font-style: normal;
                font-weight: 300;
                line-height: 100%;
                /* 14px */
            }

            h3:hover {

                background: #F6F6F5;
            }

            .inner_gems {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                padding: 12px;
                background: #F9F9F9;
                border-bottom: 1px solid #E0D7D5;
            }
        }
    }
}
@media (max-width: 1440px) {
    .mainnavbar .navbar_outer .inner_navbar {
        display: flex
    ;
        align-items: center;
        gap: 20px !important;
    }
    .mainnavbar {
        margin: 0px 20px;
        padding-top: 10px;
    }
}
@media (max-width: 990px) {
    .mainnavbar {
        display: none;
    }

    .phone_nav {
        display: block !important;
    }
}

@media (max-width: 1100px) {
    .mainnavbar .navbar_outer .inner_navbar {
        display: flex;
        align-items: center;
        gap: 20px;
    }
}

.buy_div {
    a {
        padding: 0px !important;
        border: unset !important;
    }
}

.dropdown_navbarrr {
    .btn:first-child:active, :not(.btn-check)+.btn:active {
        color: #141414;
    



      
    }
    .svg_language{
        margin-left: 5px;
    }
    .dropdown-menu.show {
        padding: 0px;
        min-width:143px ;
        border-radius: 10px;
        border: 1px solid #D9D9D9;
        background: #F2F2F4;
        box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.06);
        overflow: hidden;

    }
    .top__items{
       
display: flex;
width: 143px;
flex-direction: column;
justify-content: center;
align-items: flex-start;
h3{
    color: #020F20;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 100%; /* 12px */
}
.inner_iitems{
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 8px 10px;
    border-bottom: 1px solid #D9D9D9;
    width: 100%;
    cursor: pointer;
    &:last-child{
        border-bottom: unset;
    }
    &.disabled{
        pointer-events: none;
        opacity: 0.5;
    }
}
.inner_iitems:hover{

    background: #FFF;

}
    }
    .dropdown-toggle::after {
        display: none;
    }
    button#dropdown-basic{
        padding: 10px 15px;
        border-radius: 129.032px;
        // border: 1px solid #2C83F6;
        border: 1px solid transparent;
        background: #F2F2F2;
    }
    .btn.show {
        border: 1px solid #2C83F6 !important;
    }
    .inner_ddropp {
        display: flex;
        align-items: center;
        gap: 5px;

        h1 {
            color: #141414;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            /* 14.4px */
        }
    }
}
