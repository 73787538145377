.main_feat {
    padding: 80px 0px;

    .outer_feat {
        .inner_inte {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 63px;
            h5 {
                border: 0.1px solid #D9D9D9;
              
                width: 100%;
            }
            h2 {
                color: #2C83F6;
                white-space: nowrap;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 130%;
                /* 15.6px */
            }

            .imgline {
                width: 100%;
            }
        }

        h1 {
            color: #141414;
            font-family: Pregular;
            font-size: 60px;
            font-style: normal;
            line-height: 100%;
            /* 60px */
        }

        .inner_feat {
            padding: 40px;
            border-radius: 20px;
            background: #FFF;
            display: flex;
            justify-content: space-between;
            margin-top: 55px;

            h2 {
                color: #010102;
                font-family: Pregular;
                font-size: 24px;
                font-style: normal;
                margin-top: 80px;
                line-height: 100%;
                /* 24px */
                width: 225px;
            }
            p{
                color: #141414;
padding-top: 22px;
                font-size: 15px;
font-style: normal;
width: 338px;
font-weight: 400;
line-height: 140%; /* 21px */
            }
        }
    }
}
@media (max-width: 990px) {
    .main_feat{
        .imgline{
            display: none;
        }
        h5{
            display: block !important;
        }
       padding: 20px 0px;
       .inner_inte{
        margin-bottom: 30px !important;
       }
       .outer_feat {
           h1{
            font-size: 26px;
            width: 100%;
           }
           p{
               font-size: 16px;
   width: 100%;
           }
           .inner_feat{
            flex-direction: column;
            gap: 50px;
            margin-top: 30px;
            h2{
                margin-top: 30px;
                font-size: 22px;
            }
            p{
                width: 100%;
            }
           }
       }
    }
   }
   @media (max-width: 1200px) {
    .main_feat{
       
           .inner_feat{
            flex-direction: column;
            gap: 50px;
            margin-top: 30px;
       
           }
       }
    }
   