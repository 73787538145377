.integration_main {
    padding: 88px 0px 0px 0px;
    position: relative;
    .imgdivvv{
margin-right: -80px;
    }
.inner_patg{
    padding-top: 18px;
}
    .gradient {
        position: absolute;
        width: 100%;
        right: 0px;
        bottom: 0px;
    }

    .outer_integration {
        .inner_inte {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 63px;

            h5 {
                border: 0.1px solid #D9D9D9;
            //   margin-right: 100px;
                width: 100%;
            }

            .imgline {
                width: 100%;
                padding-right: 100px;
            }

            h2 {
                color: #2C83F6;
                font-size: 12px;
                font-style: normal;
                white-space: nowrap;
                font-weight: 500;
                line-height: 130%;
                /* 15.6px */
            }
        }

        .inner_flex {
            display: flex;
            justify-content: space-between;

            h1 {
                color: #141414;
                font-family: Pregular;
                font-size: 60px;
                font-style: normal;
                line-height: 100%;
                /* 60px */
                padding-bottom: 45px;
            }

            p {
                color: #141414;
                font-size: 18px;
                font-style: normal;
                width: 614px;
                font-weight: 400;
                line-height: 130%;
                /* 23.4px */
            }

            .another_p {
                padding-bottom: 80px;
            }

            h3 {
                color: #141414;
                font-family: Pregular;
                padding-top: 55px;
                padding-bottom: 20px;
                font-size: 30px;
                font-style: normal;
                line-height: 100%;
                /* 30px */
            }

            .five_sideimg {
                display: flex;
                align-items: end;
                justify-content: end;

                .imgdivvv {
                    height: 100%;
                }
            }
        }
    }
}

@media (max-width: 990px) {
.integration_main{
    position: relative;
}
    .gradient2{
        display: block !important;
        position: absolute;
        bottom: 20px;
        width: 100%;
        right: 0px ;
        left: 0px;
    }
    .intro{
        display: none !important;
    }
    .integration_main .outer_integration .inner_inte h5 {
        border: 0.1px solid #D9D9D9;
        margin-right: 0px;
        width: 100%;
    }
    .integration_main {
        .imgline{
            display: none;
        }
        h5{
            display: block !important;
        }
        padding: 20px 0px;

        .gradient {
            display: none;
        }

        .outer_integration {
            .inner_flex {
                flex-direction: column;
                gap: 70px;
                h1 {
                    font-size: 26px;
                    padding-bottom: 20px;

                }

                p {
                    font-size: 14px;
                    width: 100%;
                }
            }

            .inner_inte {
                margin-bottom: 30px;
            }

            .imgdivvv {
                display: none;
            }

            .imgdivvv1 {
                display: block !important;
            }

            .imgline {
                width: unset !important;
                padding: unset;
            }

        }
    }
}