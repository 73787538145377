.details_blogges {
    .bllogs_details {
        position: relative;
        height: 360px;
        overflow: hidden;
.imgtop_side{
    width: 100%;
    height: 100%;
}
        .latest_blog_text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
        }

        h1 {
            color: #141414;
            text-align: center;
            font-family: pregular;
            font-size: 60px;
            font-style: normal;
            line-height: 100%;
            /* 60px */
        }
    }

    .bottom_top {
        padding: 40px 0px;
        background: #FFF;

        .bottom-cards {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 0.625vw; // 10px -> (10 / 1600) * 100

            .single-card {
                border-radius: 0.625vw; // 10px -> (10 / 1600) * 100
                border: 0.0625vw solid #E0E4E9; // 1px -> (1 / 1600) * 100
                background: #F3F6F9;
                padding: 0.625vw 0.625vw 0; // 10px 10px 0px -> (10 / 1600) * 100
                transition: 0.3s linear;

                margin-top: 30px;

                .main-img {
                    border-radius: 0.375vw; // 6px -> (6 / 1600) * 100
                    width: 100%;
                    height: 12.875vw; // 206px -> (206 / 1600) * 100
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .bottom-text {
                    padding: 20px 15px;


                    h5 {
                        color: #010102;
                        font-family: Pregular;
                        font-size: 18px;
                        font-style: normal;
                        line-height: 100%;
                        /* 18px */
                    }

                    h6 {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        /* Limits to 2 lines */
                        -webkit-box-orient: vertical;
                        padding-top: 17px;
                        // border-bottom: 0.0625vw solid #E0E4E9; /* 1px -> (1 / 1600) * 100 */
                        color: #141414;
                        margin-bottom: 10px;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%;
                        /* 19.6px */
                    }

                    .inner-twice {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding-top: 17px; // 17px -> (17 / 1600) * 100
                        border-top: 0.0625vw solid #E0E4E9;
                        /* 1px -> (1 / 1600) * 100 */

                        p {
                            color: #424C58;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 140%;
                            /* 19.6px */
                        }

                        a {
                            color: #2C83F6;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 140%;
                            /* 19.6px */
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 5px; // 5px -> (5 / 1600) * 100
                        }
                    }
                }
            }
        }

        .bottom_section {

            padding: 10px;
            border-radius: 20px;
            background: #F7F7FD;
            display: grid;
            grid-template-columns: 1fr 1fr;

            h2 {
                color: #010102;
                font-family: pregular;
                font-size: 32px;
                font-style: normal;
                line-height: 100%;
                /* 32px */
                padding-top: 25px;
            }

            p {
                overflow: hidden;
                color: #141414;
                text-overflow: ellipsis;
                font-size: 18px;
                padding-top: 25px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                /* 25.2px */
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 5;
                align-self: stretch;
            }

            .innerbottom_side {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 25px;
                padding-top: 25px;
                border-top: 1px solid #E0E4E9;

                h3 {
                    color: #424C58;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;
                    /* 22.4px */
                }

                button {
                    color: #2C83F6;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;
                    /* 22.4px */
                    background-color: unset;
                    border: unset;
                    display: flex;
                    align-items: center;
                    gap: 5px;
                }
            }
        }

        .seemore_div {
            padding-top: 55px;
            padding-bottom: 60px;
            display: flex;
            align-items: center;
            justify-content: center;

            button {
                color: #141414;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
                /* 14.4px */
                padding: 17px 34px;
                border-radius: 129.032px;
                background: #F7F7FD;
                border: unset;
                margin: 0 auto;
            }
        }
    }
}
@media (max-width:900px){
    .details_blogges .bottom_top .bottom_section {
        padding: 10px;
        border-radius: 20px;
        background: #F7F7FD;
        display: grid
    ;
        grid-template-columns: 1fr;
        margin-bottom: 5px;
    }
    .details_blogges .bottom_top .bottom-cards {
        display: grid
    ;
        grid-template-columns: 1fr;
        gap: 0.625vw;
    }
    .details_blogges .bottom_top .bottom-cards .single-card .main-img {
        border-radius: 0.375vw;
        width: 100%;
        height: unset;
        overflow: hidden;
    }
    .new_cartt{
        display: none !important;
    }
    .details_blogges .bllogs_details h1 {
        color: #141414;
        text-align: center;
        font-family: pregular;
        font-size: 29px;
        font-style: normal;
        line-height: 100%;
    }
    .details_blogges .bottom_top .bottom_section h2 {
        color: #010102;
        font-family: pregular;
        font-size: 18px;
        font-style: normal;
        line-height: 100%;
        padding-top: 0px;
    }
    .details_blogges .bottom_top .bottom_section p {
        overflow: hidden;
        color: #141414;
        text-overflow: ellipsis;
        font-size: 14px;
        padding-top: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        align-self: stretch;
    }
    .details_blogges .bottom_top .bottom_section .innerbottom_side h3 {
        color: #424C58;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
    }
    .details_blogges .bottom_top .bottom_section .innerbottom_side button {
        color: #2C83F6;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        background-color: unset;
        border: unset;
        display: flex
    ;
        align-items: center;
        gap: 5px;
    }
    .sectionn_new{
        padding: 20px 15px !important;
    }
    
    .details_blogges .bllogs_details {
        position: relative;
        height: 360px;
        overflow: hidden;
        padding: 17px 20px;
    }
    .details_blogges .bottom_top .seemore_div {
        padding-top: 30px;
        padding-bottom: 0px;
        display: flex
    ;
        align-items: center;
        justify-content: center;
    }   
    .details_blogges .bottom_top .bottom-cards .single-card {
        border-radius: 0.625vw;
        border: 0.0625vw solid #E0E4E9;
        background: #F3F6F9;
        padding: 0.625vw 0.625vw 0;
        transition: 0.3slinear;
        margin-top: 5px;
    }
    .details_blogges .bottom_top {
        padding: 20px 0px;
        padding-bottom: 20px;
        background: #FFF;
    }
}